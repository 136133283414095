import React from "react";
import { Grid2, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import screen1 from "../images/screen1.png";
import screen2 from "../images/screen2.png";
import screen3 from "../images/screen3.png";

import card1 from "../images/card1.png";
import card2 from "../images/card2.png";

const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 1 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 1 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
};

const hologramData = [
    { id: 1, title: "White-label, powered by tipkit", img: screen1, text: "Introducing “Cash Hotel”. Your colors, your logo, your brand", img2: card2 },
    { id: 2, title: "Multi-lingual digital tipping agent", img: screen3, text: "Improve customer service with  dynamic conversations", img2: card1 },
    { id: 3, title: "Custom conversational experiences", img: screen2, text: "Add unique components to your guest’s interaction", img2: card2 }
];

const CustomDot = ({ onClick, active }) => (
    <button
        onClick={() => onClick()}
        style={{
            width: active ? "8px" : "6px",
            height: active ? "8px" : "6px",
            margin: "0 5px",
            background: active ? "#034272" : "#bbb",
            borderRadius: "50%",
            border: "none",
            cursor: "pointer",
        }}
    />
);

const CustomLeftArrow = ({ onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            zIndex: 10
        }}
    >
        <ArrowBackIosOutlinedIcon sx={{ color: "#034272" }} />
    </button>
);

const CustomRightArrow = ({ onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            zIndex: 10
        }}
    >
        <ArrowForwardIosOutlinedIcon sx={{ color: "#034272" }} />
    </button>
);

const Innovation = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box sx={{ flexGrow: 1, px: 4, py: 8, position: "relative", mt: 8 }}>
            <Typography variant="h3" color="#034272" textAlign="center" sx={{ pb: 8, fontWeight: 400 }}>
                Innovation
            </Typography>

            <Carousel
                responsive={responsive}
                autoPlay={true}
                infinite
                showDots={false}
                dotListClass="custom-dots"
                renderDotsOutside={false}
                customDot={<CustomDot />}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
            >
                {hologramData.map((item) => (
                    <Box key={item.id} sx={{ px: 4, display: "flex", justifyContent: "center" }}>
                        <Grid2 container spacing={4} alignItems="center" justifyContent="center">
                            {/* Left Side Text */}
                            <Grid2 size={{ xs: 12, md: 5 }} sx={{ textAlign: "center", px: 2 }}>
                                <Typography variant="h4" sx={{ fontWeight: 500, color: "#034272" }}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2, color: "#666" }}>
                                    {item.text}
                                </Typography>
                            </Grid2>

                            {/* Center  Image - Hide on xs & sm */}
                            {!isSmallScreen && (
                                <Grid2 size={{ xs: 12, md: 4 }} item md={3} sx={{
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end"
                                }}>
                                    <img
                                        src={item.img2}
                                        alt="image"
                                        style={{ width: "100%", maxWidth: "300px", }}
                                    />
                                </Grid2>
                            )}


                            {/* Right Side Image */}
                            <Grid2 size={{ xs: 12, md: 3 }} sx={{ textAlign: "center", display: "flex", alignItems: { xs: "center", md: "flex-end" }, justifyContent: { xs: "center", md: "flex-end" } }}>
                                < Box component="img"
                                    src={item.img}
                                    alt="Right Visual"
                                    sx={{ width: "100%", maxWidth: { xs: "300px", md: "200px" }, }}
                                />
                            </Grid2>
                        </Grid2>
                    </Box>
                ))}
            </Carousel>
        </Box>
    );
};

export default Innovation;