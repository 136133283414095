import React, { useEffect, useState } from "react";
import animation from "../images/tipkit-animation-v2.mp4";
import tipitLogo from "../images/tipkit-full-logo.svg";
import cash from "../images/cash.png";
import laptop from "../images/laptop-screenshot3.png";
import DHlogo from "../images/dhLogo.jpg";
import MIlogo from "../images/missioninnlogo.svg";
import tdcLogo from "../images/TDC Hotel.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Innovation from "./Innovation";
import { Box, Container, Grid2, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const Home = () => {
  const [startTransition, setStartTransition] = useState(false);
  const [showHeading, setShowHeading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartTransition(true);
      setShowHeading(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (location.hash === "#innovation") {
      const element = document.getElementById("innovation");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      {/* -------------------------------------------------- HERO SECTION --------------------------------------------- */}
      <div className="relative min-h-screen overflow-hidden flex flex-col items-center justify-center">
        {/* Container for logo and heading */}
        <div className="relative w-full flex flex-col items-center justify-center">
          {/* Logo Animation */}
          <div
            className={`-mt-20 sm:w-1/2 sm:block hidden transition-transform transform duration-[2000ms] ease-in-out flex justify-center items-center`}
            style={{
              mixBlendMode: "multiply",
            }}
          >
            <video
              autoPlay
              muted
              style={{
                width: "100%",
              }}
              className="transition-all"
            >
              <source src={animation} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="sm:hidden block w-2/3">
            <img src={tipitLogo} className="w-full" />
          </div>
          {/* Heading Section */}
          <div
            className={`sm:-mt-14 mt-6 sm:opacity-0 opacity-100 transition-opacity duration-1000 ease-in-out flex flex-col items-center sm:w-1/2 ${showHeading
              ? "sm:opacity-100 translate-y-0"
              : "opacity-0 translate-y-4"
              }`}
          >
            <h1 className="sm:text-4xl font-light text-primary text-center">
              {/* The Hotel Industry’s First White-Label Digital Tipping Agent */}
              Unlock The Power of Gratitude
            </h1>
            <div className="grid grid-cols-1 gap-4 sm:mt-8 mt-3">
              <button
                onClick={navigateToContact}
                className="btn btn-md bg-transparent text-primary border border-primary rounded-xl px-9 hover:bg-transparent hover:text-success hover:border-success"
              >
                Unlock Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------- ABOUT --------------------------------------- */}
      <Box sx={{ background: "#f8f9fa", py: 6, textAlign: "center", px: 3 }}>
        {/* <Divider sx={{ width: "50px", height: "4px", background: "#034272", mx: "auto", mb: 2 }} /> */}
        <Typography variant="h3" sx={{ fontWeight: 500, color: "#034272", pb: 2 }}>About</Typography>
        <Typography variant="body1" sx={{
          color: "#666",
          maxWidth: "800px",
          mx: "auto",
          mt: 2,
          lineHeight: "1.8",
          borderLeft: { xs: "none", md: "4px solid #034272" },
          pl: { xs: 0, md: 5 },
          fontSize: "20px"
          // fontStyle: "italic"
        }}>
          Tipkit's digital tipping software stands as the leader in the hotel industry, thanks to its innovative white-label chatbot services. By seamlessly integrating into hotel operations, our solution provides a fully branded, customized tipping experience that enhances guest satisfaction and operational efficiency, all while preserving the hotel's unique identity.
        </Typography>
      </Box>
      {/* ------------------------------------------------- CASH BLOCK ------------------------------------------ */}
      {/* <div className="grid grid-cols-12 gap-6 items-center sm:px-16 sm:my-16 my-7">
        <div className="sm:col-span-7 col-span-8 sm:col-start-2">
          <h3 className="text-center sm:text-4xl font-normal text-primary">
            Meet <span className="text-[#39d941] font-medium">Cash</span>, your
            abundance concierge!
          </h3>
        </div>
        <div className="sm:col-span-4 col-span-4 col-end-13">
          <img src={cash} className="w-full" />
        </div>
      </div> */}
      {/* ------------------------------------------------------------------------------------------------------- */}

      <div id="innovation">
        <Innovation />
      </div>
      {/* ---------------------------------------------- REPPORTS SCREEN SCREENSHOT ----------------------------------- */}
      <Box sx={{ flexGrow: 1, py: 8, background: "#f8f9fa", mb: 5 }}>
        <Container>
          <Grid2 container spacing={4} alignItems="center">

            {/* Left Side - Text Section */}
            <Grid2 size={{ xs: 12, md: 6 }} >
              <Typography sx={{ fontSize: { xs: "1.70rem", sm: "2.125rem" }, fontWeight: 500, color: "#034272", mb: 2, textAlign: { xs: "center", md: "start" } }}>
                Enterprise solutions for payment reconciliation
              </Typography>

              <List>
                {["FLSA Compliant", "Payroll Integrations", "Robust Reporting"].map((text, index) => (
                  <ListItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon sx={{ color: "#034272", fontSize: 28 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={{ fontSize: "22px", color: "#666" }}>
                          {text}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid2>

            {/* Right Side - Image Section */}
            <Grid2 size={{ xs: 12, md: 6 }} sx={{ textAlign: "center" }}>
              <img
                src={laptop}
                alt="image" style={{ width: "100%", borderRadius: "8px" }} />
            </Grid2>

          </Grid2>
        </Container>
      </Box>
      {/* ------------------------------------------------- CLIENT LOGOS ----------------------------------------------- */}
      <div className="sm:my-7" >
        <h3 className="sm:text-5xl text-2xl font-normal text-primary text-center sm:mb-32">
          Proudly Serving
        </h3>
        <div className="flex justify-center items-center sm:my-16 my-10 sm:px-0 px-10 gap-x-6">
          <div className="flex justify-center">
            <img src={DHlogo} className="sm:w-1/3 w-2/3 object-contain" />
          </div>
          <div className="flex justify-center">
            <img
              src={MIlogo}
              className="sm:w-full w-80 sm:pe-24 object-contain"
            />
          </div>
          <div className="flex justify-center">
            <img src={tdcLogo} className="sm:w-2/3 w-80 object-contain" />
          </div>
        </div>
      </div>
      {/* ------------------------------------------------- DIALOGUE BOX --------------------------------------------   */}
      <div className="sm:my-40 sm:px-32">
        <div className="grid grid-cols-12 sm:rounded-3xl sm:p-10 p-5 items-center bg-gradient-to-r from-primary to-neutral">
          <div className="sm:col-span-6 col-span-8">
            <h3 className="sm:text-5xl text-2xl font-normal text-white text-center">
              Unlock more Cash <br /> for your staff!
            </h3>
            <div className="flex justify-center sm:mt-20 mt-7">
              <button
                onClick={navigateToContact}
                className="btn bg-transparent boder border-success sm:pb-12 sm:pt-5 sm:px-9 flex hover:bg-success hover:border-0"
              >
                <span className="sm:text-2xl text-md text-success hover:text-white">
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="mx-2"
                  />
                  Let's Go!
                </span>
              </button>
            </div>
          </div>
          <div className="sm:col-span-6 col-span-4">
            <div className="flex justify-center">
              <img src={cash} className="sm:w-2/3 w-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
