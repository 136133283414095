import React, { useState } from "react";
import { BACKEND_URL } from "../consfig";
import axios from "axios";
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid2, TextField } from "@mui/material";

const Contact = () => {
  // State for form data and errors
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    hotelName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    numberOfRooms: "",
    tippingExperience: {
      roomAttendant: false,
      valetAttendant: false,
      bellStaff: false,
      other: false,
    },
  });

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        tippingExperience: {
          ...prevData.tippingExperience,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Validate the form data
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email address is required.";
    if (!formData.hotelName.trim())
      newErrors.hotelName = "Hotel/Resort Name is required.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.city.trim()) newErrors.city = "City is required.";
    if (!formData.state.trim()) newErrors.state = "State is required.";
    if (!formData.zipCode.trim()) newErrors.zipCode = "Zip Code is required.";
    if (!formData.numberOfRooms.trim() || formData.numberOfRooms <= 0) {
      newErrors.numberOfRooms =
        "Number of Rooms is required and must be greater than 0.";
    }
    if (
      !formData.tippingExperience.roomAttendant &&
      !formData.tippingExperience.valetAttendant &&
      !formData.tippingExperience.bellStaff &&
      !formData.tippingExperience.other
    ) {
      newErrors.tippingExperience = "Please select at least one department.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form data submitted:", formData);
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${BACKEND_URL}api/email`,
        formData,
        config
      );
      // setFormSubmitted(response.data.success);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen mx-5">
      <form
        onSubmit={handleSubmit}
        className="my-32 bg-white shadow-lg rounded-xl p-6 md:p-8 max-w-xl w-full border-2 border-primary"
      >
        {/* Name Input */}
        <div className="mb-4">
          {/* <label className="block text-sm font-medium text-gray-700">
            Full Name
          </label> */}
          <TextField
            type="text"
            name="name"
            label="Name"
            size="small"
            value={formData.name}
            onChange={handleChange}
            // placeholder="Your Name"
            className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
        </div>

        {/* Email Input */}
        <div className="mb-4">
          {/* <label className="block text-sm font-medium text-gray-700">
            Email address
          </label> */}
          <TextField
            type="email"
            name="email"
            label="Email"
            size="small"
            value={formData.email}
            onChange={handleChange}
            // placeholder="Email address"
            className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )}
        </div>

        {/* Hotel/Resort Name Input */}
        <div className="mb-4">
          {/* <label className="block text-sm font-medium text-gray-700">
            Hotel/Resort Name
          </label> */}
          <TextField
            type="text"
            name="hotelName"
            label="Hotel Name"
            size="small"
            value={formData.hotelName}
            onChange={handleChange}
            // placeholder="Hotel/Resort Name"
            className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          />
          {errors.hotelName && (
            <p className="text-red-500 text-sm">{errors.hotelName}</p>
          )}
        </div>

        {/* Address Input */}
        <div className="mb-4">
          {/* <label className="block text-sm font-medium text-gray-700">
            Address
          </label> */}
          <TextField
            type="text"
            name="address"
            label="Address"
            size="small"
            value={formData.address}
            onChange={handleChange}
            // placeholder="Address"
            className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          />
          {errors.address && (
            <p className="text-red-500 text-sm">{errors.address}</p>
          )}
        </div>

        {/* City, State, Zip Code Inputs */}
        <div className="mb-4 flex space-x-2">
          <div className="flex-1">
            {/* <label className="block text-sm font-medium text-gray-700">
              City
            </label> */}
            <TextField
              type="text"
              name="city"
              label="City"
              size="small"
              value={formData.city}
              onChange={handleChange}
              // placeholder="City"
              className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
            />
            {errors.city && (
              <p className="text-red-500 text-sm">{errors.city}</p>
            )}
          </div>
          <div className="flex-1">
            {/* <label className="block text-sm font-medium text-gray-700">
              State
            </label> */}
            <TextField
              type="text"
              name="state"
              label="State"
              size="small"
              value={formData.state}
              onChange={handleChange}
              // placeholder="State"
              className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
            />
            {errors.state && (
              <p className="text-red-500 text-sm">{errors.state}</p>
            )}
          </div>
          <div className="flex-1">
            {/* <label className="block text-sm font-medium text-gray-700">
              Zip Code
            </label> */}
            <TextField
              type="text"
              name="zipCode"
              label="Zip Code"
              size="small"
              value={formData.zipCode}
              onChange={handleChange}
              // placeholder="Zip Code"
              className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
            />
            {errors.zipCode && (
              <p className="text-red-500 text-sm">{errors.zipCode}</p>
            )}
          </div>
        </div>

        {/* Number of Rooms Input */}
        <div className="mb-4">
          {/* <label className="block text-sm font-medium text-gray-700">
            Number of Rooms
          </label> */}
          <TextField
            type="number"
            name="numberOfRooms"
            label="Number Of Rooms"
            size="small"
            value={formData.numberOfRooms}
            onChange={handleChange}
            // placeholder="Number of Rooms"
            className="mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          />
          {errors.numberOfRooms && (
            <p className="text-red-500 text-sm">{errors.numberOfRooms}</p>
          )}
        </div>

        {/* Tipping Experience Checkboxes */}
        <div className="mb-6 mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Unlock the power of gratitude:
          </label>
          <FormGroup>
            <Grid2 container spacing={0}>
              {["roomAttendant", "valetAttendant", "bellStaff", "other"].map((experience, index) => (
                <Grid2 size={{ xs: 12, sm: 6 }} key={experience}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={experience}
                        checked={formData.tippingExperience[experience]}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={experience.charAt(0).toUpperCase() + experience.slice(1).replace(/([A-Z])/g, " $1")}
                  />
                </Grid2>
              ))}
            </Grid2>
            {errors.tippingExperience && (
              <FormHelperText sx={{ color: "red" }}>{errors.tippingExperience}</FormHelperText>
            )}
          </FormGroup>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-primary btn-wide text-white hover:text-primary font-medium py-2 px-4 rounded-lg hover:bg-white hover:border hover:border-primary transition duration-300"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
